import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class RequestClient {

  constructor(private http: HttpClient) {}

  createAuthorizationHeader() {
    let headers = new HttpHeaders().append( "Authorization","Bearer " + localStorage.getItem('currentUser') );
    return headers;
  }

  get(url) {
    return this.http.get(url, {
      headers: this.createAuthorizationHeader()
    });
  }

  post(url, data) {
    return this.http.post(url, data, {
      headers: this.createAuthorizationHeader()
    });
  }
}
